@import "../../../styles/global.scss";

$small: 728px;

.gettingStarted {
  max-width: 720px;
  padding: 2rem;
  margin: 0 auto;

  ul {
    list-style: circle;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    
    li {
      list-style-type: disc;
      padding-bottom: 5px;
      padding-left: 0.5rem;
      text-indent: -0.5rem;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  h3 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h5 {
    margin-bottom: 5px;
  }
  
  p {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  /* Special spacing for test sections */
  p:nth-of-type(1) {
    margin-bottom: 1rem;
  }

  p:nth-of-type(3), p:nth-of-type(4) {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  p:nth-of-type(5) {
    margin-top: 2rem;
  }
}