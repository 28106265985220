@import "../../styles/global.scss";

.loadingContainer {
  width: 100%;
  min-height: 100vh;
}

.header {
  position: relative;
  height: 90px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    margin-right: 20px;
    margin-left: 20px;

    > span {
      &:first-child, &:last-child {
        width: 400px;
      }

      &:nth-child(2) {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.logo {
  cursor: default;
  max-height: $header_height - 1px; /* minus border */
  padding: 10px;
}

main {
  width: 100%;
  min-height: $no_header;
  background-color: #f5f6fa;
  
  @media (min-width: 480px) {
    padding: 3rem;
  }

  @media (min-width: 0) {
    padding: 20px;
  }
}

.loadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16rem;
  
  p {
    margin-top: 1rem;
    color: #333;
    font-size: 1.2rem;
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 