.tooltipContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.iconWrapper {
  padding-left: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71717a;
  cursor: help;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #007bd0;
  }
}

.tooltipContent {
  position: absolute;
  padding: 8px 12px;
  background-color: #333;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  max-width: 300px;
  text-wrap: auto;
  width: max-content;

  // Top position (default)
  &.tooltip--top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  // Right position
  &.tooltip--right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 8px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent #333 transparent transparent;
    }
  }

  // Bottom position
  &.tooltip--bottom {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #333 transparent;
    }
  }

  // Left position
  &.tooltip--left {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #333;
    }
  }
}

.hideTooltip {
  display: none !important;  /* Hide tooltip when modal is open */
}
