.videoContainer {
  position: relative;
  padding-top: 56.25%; // 16:9 aspect ratio
  width: 100%;
  height: 0;
}

.videoFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
} 