.searchField {
  position: relative;
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  padding: 10px 14px;
  font-size: 14px;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  padding-right: 32px;

  &:hover {
    background-color: #f4f4f5;
  }

  &:focus {
    outline: none;
    border-color: #2563eb;
  }
}

.iconWrapper {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #71717a;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px;
  margin-top: 4px;
  z-index: 10;

  &--bottom-right {
    left: auto;
    right: 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.item {
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #f4f4f5;
  }

  &--default {
    padding: 10px 14px;
  }

  &--small {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.addNewItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  color: #2563eb;
  border-top: 1px solid #e4e4e7;
  margin-top: 4px;
  padding-top: 4px;

  &:hover {
    background-color: #f4f4f5;
  }

  &--default {
    padding: 10px 14px;
  }

  &--small {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.bold {
  font-weight: bold;
}

