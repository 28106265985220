.container {
    padding: 0 24px;
  
    h1 {
        margin-bottom: 16px;
    }
}

.introSection {
    margin-bottom: 32px;
}

.description {
    margin-bottom: 16px;
    line-height: 1.5;
}

.descriptionDivider {
    height: 1px;
    background-color: hsl(0, 0%, 90%);
    width: 100%;
}

.searchBar {
    margin-bottom: 32px;

    .inputWrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .iconWrapper {
        position: absolute;
        left: 14px;
        top: 52%;
        transform: translateY(-50%);
        color: #acaba8;
        pointer-events: none;
    }

    input {
        width: 100%;
        padding: 12px 12px 12px 38px;
        font-size: 14px;
        border: 1px solid #dfdedd;
        border-radius: 6px;
        background-color: #fdfdfc;

        &:hover {
            border-color: #143CFF;
        }

        &:focus {
            outline: none;
            border-color: #143CFF;
            box-shadow: 0 0 4px #143CFF;
        }
    }
}

.faqsGrid {
    display: grid;
    gap: 32px;
}

.categorySection {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
}

.categoryName {
    font-weight: 600;
    color: hsl(0, 0%, 0%);
    font-size: 20px;
    margin-bottom: 6px;
}

.faqsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.faqItem {
    margin-bottom: 4px;
}

.questionButton {
    width: 100%;
    padding: 0 0 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: hsl(0, 0%, 20%);
    transition: color 0.2s;

    &:hover {
        color: #143CFF;
    }

    span {
        flex: 1;
        margin-right: 16px;
    }

    svg {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-top: 3px;
    }
}

.answer {
    padding: 0 0 16px 32px;
    font-size: 14px;
    color: hsl(0, 0%, 35%);
    line-height: 1.5;
    border-bottom: 1px solid hsl(0, 0%, 90%);

    strong {
        font-weight: 600;
        color: hsl(0, 0%, 20%);
    }

    p {
        margin: 0 0 12px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    /* Enhanced list styles to ensure bullet points appear */
    ul {
        margin: 8px 0 16px 0;
        padding-left: 20px;
        list-style-type: disc !important;
        list-style-position: outside;
        display: block;
    }
    
    li {
        display: list-item !important; /* Force list item display */
        list-style: disc outside !important; /* Explicitly set bullet style */
        padding-left: 6px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    /* Add styles for links */
    a {
        color: #143CFF;
        text-decoration: underline;
        
        &:hover {
            text-decoration: none;
        }
        
        &:visited {
            color: #7262cc;
        }
    }
}

.divider {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: hsl(0, 0%, 90%);
}

// Add a specific class for the first question in each category
.faqItem:first-child .questionButton {
    padding-top: 0;
}

// For collapsed questions that don't show answers, add a divider
.faqItem:not(:last-child) .questionButton:not([aria-expanded="true"]) {
    border-bottom: 1px solid hsl(0, 0%, 90%);
}
