.container {
  padding: 0 24px;

  h1 {
    margin-bottom: 16px;
  }
}

.introSection {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 16px;
  line-height: 1.5;
}

.descriptionDivider {
  height: 1px;
  background-color: hsl(0, 0%, 90%);
  width: 100%;
}

.searchBar {
  margin-bottom: 32px;

  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .iconWrapper {
    position: absolute;
    left: 14px;
    top: 52%;
    transform: translateY(-50%);
    color: #acaba8;
    pointer-events: none;
  }

  input {
    width: 100%;
    padding: 12px 12px 12px 38px;
    font-size: 14px;
    border: 1px solid #dfdedd;
    border-radius: 6px;
    background-color: #fdfdfc;

    &:hover {
      border-color: #143CFF;
    }

    &:focus {
      outline: none;
      border-color: #143CFF;
      box-shadow: 0 0 4px #143CFF;
    }
  }
}

.resourcesGrid {
  display: grid;
  gap: 32px;
}

.categorySection {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 24px;
  gap: 16px;
}

.categoryName {
  font-weight: 600;
  color: hsl(0, 0%, 0%);
  font-size: 18px;
  margin-bottom: 4px;
}

.resourcesList {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: hsl(0, 0%, 35%);
    text-decoration: underline;
    display: flex;
    align-items: center;
    
    &:hover {
      color: hsl(0, 0%, 20%);
    }
  }
}

.resourceLink {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.resourceText {
  margin-left: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
  flex: 1;
}

.resourceIcon {
  font-size: 16px;
  color: hsl(0, 0%, 50%);
  flex-shrink: 0;
  min-width: 16px;
  min-height: 16px;
  display: inline-flex;
}

.divider {
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: hsl(0, 0%, 90%);
}
